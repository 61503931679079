export const eventList = {
    PL: [
        {
            title: 'Premiera książki „Zrolowany wrześniowy Vogue”',
            date: {
                start: new Date(2020, 4, 22, 19, 0),
                end: new Date(2020, 4, 22, 21, 15),
            },
            organiser: 'Spółdzielnia Ogniwo',
            description: 'Prowadzenie Tomasz Charnas',
            urls: [
                {
                    type: 'facebook',
                    url: 'https://www.facebook.com/ogniwokrakow/videos/665702967311312/',
                },
            ],
        },
        {
            title: 'Premiera książki „Lakier”',
            date: {
                start: new Date(2022, 10, 28, 18, 0),
                end: new Date(2022, 10, 28, 19, 0),
            },
            organiser: 'Wydawnictwo Granda',
            description: 'Rozmowę poprowadzi Mich Pabian',
            urls: [
                {
                    type: 'facebook',
                    url: 'https://www.facebook.com/events/1466023520585790/',
                },
                {
                    type: 'youtube',
                    url: 'https://www.youtube.com/watch?v=hgOhEqrDlJY',
                },
            ],
        },
        {
            title: 'Spotkanie autorskie',
            date: {
                start: new Date(2023, 0, 11, 19, 0),
                end: new Date(2023, 0, 11, 20, 0),
            },
            organiser: 'Kanał Książkowy',
            description: 'Prowadzi Gabriel Augustyn',
            urls: [
                {
                    type: 'facebook',
                    url: 'https://www.facebook.com/events/3094736734159723/',
                },
                {
                    type: 'youtube',
                    url: 'https://www.youtube.com/watch?v=dQO1ZiJ1lEE',
                },
            ],
        },
        {
            title: 'Podcast „Zmierzch familijnych tyranów”',
            date: {
                start: new Date(2023, 1, 16, 15, 0),
                end: new Date(2023, 1, 16, 16, 0),
            },
            organiser: 'Nowy Gramofon',
            description: 'Rozmowa z Patrykiem Kosendą',
            urls: [
                {
                    type: 'spotify',
                    url: 'https://open.spotify.com/episode/4LBOjCZj2lJbDrSIIqPGCc',
                },
            ],
        },
        {
            title: 'Reportaż „Goście Radziwiłłów 2023”',
            date: {
                start: new Date(2023, 8, 15, 12, 0),
                end: new Date(2023, 8, 15, 13, 0),
            },
            organiser: 'Kultura u Podstaw',
            description: 'Materiał audio z udziałem uczestników rezydencji literackich „Goście Radziwiłłów” w Antoninie',
            urls: [
                {
                    type: '',
                    url: 'https://kulturaupodstaw.pl/audio/91750/',
                },
            ],
        },
        {
            title: 'Festiwal Czytania',
            date: {
                start: new Date(2023, 10, 25, 16, 0),
                end: new Date(2023, 10, 25, 17, 0),
            },
            organiser: 'Książnica Pomorska',
            description: 'Spotkanie z autorami książek nominowanych do Nagrody Literackiej „Jantar”. Prowadzi Konrad Wojtyła',
            urls: [],
        },
    ],
    EN: [],
};

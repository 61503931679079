import { Outlet } from 'react-router-dom';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { LanguageContext } from '../contexts/LanguageContext';
import { INITIAL_LANGUAGE } from '../utils/constants';
import { books } from '../assets/content/books';
import { textsList } from '../assets/content/texts';
import Header from '../components/header';
import Footer from '../components/footer';
import ScrollToTop from '../components/scroll-to-top';

export default function MainLayout() {
    const [language, setLanguage] = useLocalStorage('language', INITIAL_LANGUAGE);

    return (
        <LanguageContext.Provider value={{ language, setLanguage }}>
            <ScrollToTop />
            <Header />
            <Outlet context={{ books, textsList }} />
            <Footer />
        </LanguageContext.Provider>
    );
}

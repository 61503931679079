import { AiFillInstagram, AiFillFacebook, AiOutlineLink, AiFillYoutube } from 'react-icons/ai';
import { FaBook, FaSpotify } from 'react-icons/fa';

export default function Icon({ type, ignoreColor }) {
    switch (type) {
        case 'instagram':
            return ignoreColor ? (
                <AiFillInstagram />
            ) : (
                <AiFillInstagram style={{ color: '#c13584' }} />
            );
        case 'facebook':
            return ignoreColor ? (
                <AiFillFacebook />
            ) : (
                <AiFillFacebook style={{ color: '#4267b2' }} />
            );
        case 'youtube':
            return ignoreColor ? <AiFillYoutube /> : <AiFillYoutube style={{ color: '#ff0000' }} />;
        case 'spotify':
            return ignoreColor ? <FaSpotify /> : <FaSpotify style={{ color: '#1db954' }} />;
        case 'book':
            return <FaBook />;
        default:
            return <AiOutlineLink />;
    }
}

import { useEffect, useState } from 'react';
import { useWindowWidth } from '@wojtekmaj/react-hooks';
import Logo from './logo';
import Navbar from './navbar';
import Socials from './socials';
import LanguageToggle from './language-toggle';
import { Spin as Hamburger } from 'hamburger-react';

export default function Header() {
    const width = useWindowWidth();
    const [showHamburger, setShowHamburger] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (width < 1020) {
            setShowHamburger(true);
        } else {
            setShowHamburger(false);
            setIsOpen(false);
        }
    }, [width]);

    return (
        <header>
            {showHamburger ? (
                <div className="mobile-display">
                    <div
                        className={`fixable-wrapper${isOpen ? ' fixed' : ''}`}
                        onClick={() => (isOpen ? setIsOpen(false) : undefined)}>
                        <Logo />
                        <div className="hamburger-button">
                            <Hamburger toggled={isOpen} toggle={setIsOpen} size={20} />
                        </div>
                    </div>
                    {isOpen && (
                        <div className="overlay" onClick={() => setIsOpen(false)}>
                            <Navbar />
                            <LanguageToggle />
                        </div>
                    )}
                </div>
            ) : (
                <div className="desktop-display">
                    <Logo />
                    <Navbar />
                    <LanguageToggle />
                    <Socials />
                </div>
            )}
        </header>
    );
}

import { useContext } from 'react';
import { LanguageContext } from '../contexts/LanguageContext';
import { about } from '../assets/content/localisation';

export default function About() {
    const { language } = useContext(LanguageContext);

    return (
        <section id="about">
            <h2>{about[language].header}</h2>
            <div className="about-wrapper">
                <div className="about-description">
                    {about[language].description.map((p, i) => (
                        <p key={i}>{p}</p>
                    ))}
                </div>
                <div className="portrait">
                    <img src={require('../assets/images/portrait.jpg')} alt="portrait" />
                </div>
            </div>
        </section>
    );
}

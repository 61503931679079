import { Link, useOutletContext } from 'react-router-dom';

export default function Books() {
    const { books } = useOutletContext();

    return (
        <section id="books">
            <ul>
                {books.map((book) => (
                    <li key={book.id}>
                        <Link to={`/books/${book.id}`}>
                            <img
                                src={require('../assets/images/' + book.id + '-thumb.png')}
                                alt={`${book.title}`}
                            />
                        </Link>
                    </li>
                ))}
            </ul>
        </section>
    );
}

import { Link } from 'react-router-dom';

export default function ErrorPage() {
    return (
        <div id="error-page">
            <h1>Ups!</h1>
            <p>Nie znaleziono podanej strony.</p>
            <Link to="/">Wróćmy do domu</Link>
        </div>
    );
}

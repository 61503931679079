import { useContext, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { useWindowWidth } from '@wojtekmaj/react-hooks';
import { INLINE_MARGINS_RATIO, MAX_PDF_VIEWER_WIDTH } from '../utils/constants';
import { LanguageContext } from '../contexts/LanguageContext';
import { viewer } from '../assets/content/localisation';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function PdfViewer({ filename }) {
    const { language } = useContext(LanguageContext);
    const width = useWindowWidth();
    const pdfViewerWidth =
        width > MAX_PDF_VIEWER_WIDTH * (1 + INLINE_MARGINS_RATIO) + 20
            ? MAX_PDF_VIEWER_WIDTH
            : (width - 15) * (1 - INLINE_MARGINS_RATIO);

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber((prevPageNumber) => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    return (
        <div className="pdf-viewer">
            <Document
                file={require(`../assets/documents/${filename}.pdf`)}
                options={{ workerSrc: '/pdf.worker.js' }}
                onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} width={pdfViewerWidth} />
            </Document>
            <div className="pdf-controls">
                <div className="pdf-buttons">
                    <button
                        className={`btn${pageNumber <= 1 ? ' disabled' : ''}`}
                        type="button"
                        disabled={pageNumber <= 1}
                        onClick={previousPage}>
                        {viewer[language].previous}
                    </button>
                    <button
                        className={`btn${pageNumber >= numPages ? ' disabled' : ''}`}
                        type="button"
                        disabled={pageNumber >= numPages}
                        onClick={nextPage}>
                        {viewer[language].next}
                    </button>
                </div>
                <p>
                    {viewer[language].page} <strong>{pageNumber || (numPages ? 1 : '--')}</strong>{' '}
                    {viewer[language].of} <strong>{numPages || '--'}</strong>
                </p>
            </div>
        </div>
    );
}

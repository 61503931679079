import { useContext } from 'react';
import { LanguageContext } from '../contexts/LanguageContext';
import { texts } from '../assets/content/localisation';
import { textsList } from '../assets/content/texts';
import { Link } from 'react-router-dom';

export default function Events() {
    const { language } = useContext(LanguageContext);

    return (
        <section id="texts">
            <h3>{texts[language].articles}</h3>
            {textsList.articles.map((item) => (
                <p key={item.url}>
                    <a href={item.url} target="_blank" rel="noreferrer">
                        {item.title}
                    </a>
                </p>
            ))}

            <h3>{texts[language].reviews}</h3>
            {textsList.reviews.map((item) => (
                <p key={item.url}>
                    <a href={item.url} target="_blank" rel="noreferrer">
                        {item.title}
                    </a>
                </p>
            ))}

            <h3>{texts[language].dramas}</h3>
            {textsList.dramas.map((item) => (
                <p key={item.title}>
                    <Link to={`/texts/${item.id}`}>{item.title}</Link>
                </p>
            ))}

            <h3>{texts[language].prose}</h3>
            {textsList.prose.map((item) => (
                <p key={item.url}>
                    <a href={item.url} target="_blank" rel="noreferrer">
                        {item.title}
                    </a>
                </p>
            ))}
        </section>
    );
}

import { SOCIALS } from '../assets/content/socials';
import LinkedIconList from './linked-icon-list';

export default function Socials() {
    return (
        <div className="socials">
            <LinkedIconList items={SOCIALS} ignoreColor={true} />
        </div>
    );
}

import { useContext } from 'react';
import { LanguageContext } from '../contexts/LanguageContext';
import { LANGUAGES as languages } from '../utils/constants';
import LinkedIconList from './linked-icon-list';

export default function EventTile({ event }) {
    const { language } = useContext(LanguageContext);
    const locale = languages.find((item) => item.id === language).locale;

    return (
        <li className="event-tile">
            <div className="date">
                <p>
                    {event.date.start.toLocaleDateString(locale, {
                        weekday: 'long',
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric',
                    })}
                    ,{' '}
                    {event.date.start.toLocaleTimeString(locale, {
                        hour: 'numeric',
                        minute: '2-digit',
                    })}
                </p>
            </div>
            <div className="organiser">{event.organiser}</div>
            <h4>{event.title}</h4>
            <p className="event-description">{event.description}</p>
            {event.urls.length !== 0 && <LinkedIconList items={event.urls} ignoreColor={false} />}
        </li>
    );
}

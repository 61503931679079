import Icon from './icon';

export default function LinkedIconList({ items, ignoreColor }) {
    return (
        <ul className="linked-icon-list">
            {items.map((item) => (
                <li key={item.type}>
                    <a href={item.url} target="_blank" rel="noreferrer">
                        <Icon type={item.type} ignoreColor={ignoreColor} />
                    </a>
                </li>
            ))}
        </ul>
    );
}

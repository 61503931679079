import { useContext } from 'react';
import { LanguageContext } from '../contexts/LanguageContext';
import { events } from '../assets/content/localisation';
import EventTile from './event-tile';

export default function EventTiles({ eventsList }) {
    const { language } = useContext(LanguageContext);

    if (eventsList.length === 0) {
        return <p className="info">{events[language].empty}</p>;
    }

    return (
        <ul className="event-tiles">
            {eventsList.map((event) => (
                <EventTile key={event.date.start} event={event} />
            ))}
        </ul>
    );
}

import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { LanguageContext } from '../contexts/LanguageContext';
import { navigation } from '../assets/content/localisation';

export default function Navbar() {
    const { language } = useContext(LanguageContext);

    return (
        <nav>
            <NavLink to="/" className={({ isActive }) => (isActive ? 'link active' : 'link')}>
                {navigation[language].books}
            </NavLink>
            <NavLink to="/texts" className={({ isActive }) => (isActive ? 'link active' : 'link')}>
                {navigation[language].texts}
            </NavLink>
            <NavLink to="/events" className={({ isActive }) => (isActive ? 'link active' : 'link')}>
                {navigation[language].events}
            </NavLink>
            <NavLink to="/about" className={({ isActive }) => (isActive ? 'link active' : 'link')}>
                {navigation[language].about}
            </NavLink>
            <NavLink
                to="/contact"
                className={({ isActive }) => (isActive ? 'link active' : 'link')}>
                {navigation[language].contact}
            </NavLink>
        </nav>
    );
}

import { Navigate, useOutletContext, useParams } from 'react-router-dom';
import PdfViewer from '../components/pdf-viewer';

export default function Text() {
    const { textsList } = useOutletContext();
    let { textId } = useParams();

    let text;
    for (const group in textsList) {
        text = textsList[group].find((item) => item.id === textId);
        if (text) {
            break;
        }
    }

    return text ? (
        <section id="text">
            <h3>{text.title}</h3>
            <PdfViewer filename={text.id} />
        </section>
    ) : (
        <Navigate to="/texts" replace />
    );
}

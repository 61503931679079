import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import './index.scss';

import MainLayout from './pages/main-layout';
import Books from './pages/books';
import Book from './pages/book';
import Texts from './pages/texts';
import Text from './pages/text';
import Events from './pages/events';
import About from './pages/about';
import Contact from './pages/contact';
import ErrorPage from './pages/error-page';

const router = createBrowserRouter([
    {
        path: '/',
        element: <MainLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                element: <Books />,
            },
            {
                path: 'books',
                element: <Books />,
            },
            {
                path: 'books/:bookId',
                element: <Book />,
            },
            {
                path: 'texts',
                element: <Texts />,
            },
            {
                path: 'texts/:textId',
                element: <Text />,
            },
            {
                path: 'events',
                element: <Events />,
            },
            {
                path: 'about',
                element: <About />,
            },
            {
                path: 'contact',
                element: <Contact />,
            },
        ],
    },
]);

ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);

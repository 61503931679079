export const navigation = {
    PL: {
        books: 'Książki',
        texts: 'Teksty',
        events: 'Wydarzenia',
        about: 'O mnie',
        contact: 'Kontakt',
    },
    EN: {
        books: 'Books',
        texts: 'Texts',
        events: 'Events',
        about: 'About',
        contact: 'Contact',
    },
};

export const events = {
    PL: {
        header: 'Wydarzenia',
        upcoming: 'Nadchodzące',
        past: 'Minione',
        empty: 'Brak wydarzeń',
    },
    EN: {
        header: 'Events',
        upcoming: 'Upcoming',
        past: 'Past',
        empty: 'No events',
    },
};

export const about = {
    PL: {
        header: 'O mnie',
        description: [
            'Jarek Skurzyński (1980 w Szczecinie), prozaik, eseista i dramatopisarz; w 2015 opublikował esej „Tylko ateiści zostaną zbawieni”, debiut prozatorski „Zrolowany wrześniowy Vogue” wydał w 2020 w Korporacji Ha!art. W 2013 roku otrzymał tytuł doktora nauk humanistycznych w dziedzinie filozofii. W 2021 roku był stypendystą Ministra Kultury, Dziedzictwa Narodowego i Sportu.',
            'W swojej twórczości rozwija tematy związane z LGBTQ, tożsamością, wyobcowaniem, a także funkcjonowaniem jednostek w opresyjnych systemach. Dzięki mediacyjnemu charakterowi literatura stanowi dla niego możliwość wyjścia poza doświadczenie opresji, a także jest głównym medium refleksji nad normami społecznymi oraz nie zawsze uświadomionym przymusem poddawania się im w codziennym życiu. W dramatach i prozie przedstawia wyuczone mechanizmy powielania traumy jednostkowej jak i zbiorowej. W swoich utworach dąży do polifoniczności formy, co prowadzi do literackiego rozkładu na części pierwsze takich pojęć jak różnorodność, wspólnota, współistnienie.',
        ],
    },
    EN: {
        header: 'About',
        description: [
            'Jarek Skurzyński (1980), novelist, essayist and playwright, bookseller and philosopher by education; published the essays “Tylko ateiści zostaną zbawieni” (2015, Only Atheists Will Be Saved), “Jedenaście medytacji nad istnieniem dzieła sztuki” (2021, Eleven meditations on existence of a work of art), his prose debut “Zrolowany wrześniowy Vogue” (Rolled-up September Vogue) was published in 2020 at Korporacja Ha!Art. In 2021, he received a scholarship from the Minister of Culture, and in 2022 Literary Residency “Goście Radziwiłłów”.',
            'In my work, I develop topics related to LGBTQ+, identity, alienation, as well as the functioning of individuals in oppressive systems. Thanks to its mediating nature, literature is for me an opportunity to go beyond the experience of oppression, and is also the main medium for reflection on social norms and the not always conscious compulsion to submit to them in everyday life. I am interested in the learned mechanisms of duplicating individual and collective trauma. In my works, I strive for polyphony of form, which leads to the literary decomposition of concepts such as diversity, community, and coexistence. I am interested in the concept of an open-ended novel because I do not believe in the existence of ready-made solutions or in the validity of moralizing literature.',
        ],
    },
};

export const contact = {
    PL: {
        header: 'Kontakt',
        name: 'imię i nazwisko',
        email: 'email',
        message: 'wiadomość',
        send: 'wyślij',
    },
    EN: {
        header: 'Contact me',
        name: 'name',
        email: 'email',
        message: 'message',
        send: 'send',
    },
};

export const texts = {
    PL: {
        articles: 'Artykuły',
        reviews: 'Recenzje',
        dramas: 'Dramaty',
        prose: 'Proza',
    },
    EN: {
        articles: 'Articles',
        reviews: 'Reviews',
        dramas: 'Dramas',
        prose: 'Prose',
    },
};

export const viewer = {
    PL: {
        page: 'strona',
        of: 'z',
        next: 'następna',
        previous: 'poprzednia',
    },
    EN: {
        page: 'page',
        of: 'of',
        next: 'next',
        previous: 'previous',
    },
};

import { useContext, useState } from 'react';
import { LanguageContext } from '../contexts/LanguageContext';
import { AxiosContext } from '../contexts/AxiosContext';
import { contact } from '../assets/content/localisation';
import ContactForm from '../components/contact-from';

export default function Contact() {
    const { language } = useContext(LanguageContext);
    const [isPending, setIsPending] = useState(false);
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);

    return (
        <AxiosContext.Provider
            value={{
                isPending,
                setIsPending,
                response,
                setResponse,
                error,
                setError,
            }}>
            <section id="contact">
                <h2>{contact[language].header}</h2>
                <div className="contact-form">
                    <ContactForm />
                </div>
            </section>
        </AxiosContext.Provider>
    );
}

import { useContext, useState } from 'react';
import axios from 'axios';
import { LanguageContext } from '../contexts/LanguageContext';
import { AxiosContext } from '../contexts/AxiosContext';
import { contact } from '../assets/content/localisation';
import { FaUser, FaAt, FaEnvelope } from 'react-icons/fa';

export default function ContactForm() {
    const { language } = useContext(LanguageContext);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const { isPending, setIsPending, setResponse, setError } = useContext(AxiosContext);

    axios.interceptors.request.use(
        function (config) {
            setIsPending(true);
            setError(null);
            setResponse(null);
            return config;
        },
        function (error) {
            return Promise.reject(error);
        }
    );
    axios.interceptors.response.use(
        function (response) {
            setIsPending(false);
            return response;
        },
        function (error) {
            setIsPending(false);
            return Promise.reject(error);
        }
    );

    const sendEmail = (e) => {
        // prevent page refresh
        e.preventDefault();
        // send request
        axios.defaults.headers.post['Content-Type'] = 'application/json';
        axios
            .post('https://formsubmit.co/ajax/21000fd1d43a5441e04d4c16d48217ff', {
                name: name,
                email: email,
                message: message,
            })
            .then((response) => setResponse(response))
            .catch((error) => setError(error));
        // reset form inputs
        [setName, setEmail, setMessage].forEach((f) => f(''));
    };

    return (
        <form onSubmit={sendEmail}>
            <input type="hidden" name="_captcha" value="false"></input>
            <div className="contact-input">
                <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder={contact[language].name}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />
                <label className="contact-icon" htmlFor="name">
                    <FaUser />
                </label>
            </div>
            <div className="contact-input">
                <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder={contact[language].email}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <label className="contact-icon" htmlFor="email">
                    <FaAt />
                </label>
            </div>
            <div className="contact-input">
                <textarea
                    name="message"
                    id="message"
                    rows="6"
                    placeholder={contact[language].message}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                />
                <label className="contact-icon" htmlFor="message">
                    <FaEnvelope />
                </label>
            </div>
            <button
                type="submit"
                className={`btn${isPending ? ' disabled' : ''}`}
                disabled={isPending}>
                {contact[language].send}
            </button>
        </form>
    );
}

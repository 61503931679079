import { useContext } from 'react';
import { LanguageContext } from '../contexts/LanguageContext';
import { LANGUAGES as languages } from '../utils/constants';

export default function LanguageToggle() {
    const { language, setLanguage } = useContext(LanguageContext);

    const toggleLanguage = () => {
        const curr = languages.findIndex((item) => item.id === language);
        const next = (curr + 1) % languages.length;
        setLanguage(languages[next]?.id);
    };

    return (
        <div className="language-toggle">
            <button onClick={toggleLanguage}>{language}</button>
        </div>
    );
}

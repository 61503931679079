export const LANGUAGES = [
    {
        id: 'PL',
        locale: 'pl-PL',
        language: 'polski',
    },
    {
        id: 'EN',
        locale: 'en-GB',
        language: 'English',
    },
];
export const INITIAL_LANGUAGE = LANGUAGES[0].id;

export const MAX_PDF_VIEWER_WIDTH = 800;
export const INLINE_MARGINS_RATIO = 0.08;
